import React, { useState, useRef, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import fetchy from './lib/fetchy.js';
import { Time } from './lib/Time.js';
import { notifyReferSubscribe } from './lib/notify.js';

const Interface = lazy(() => import('./components/Interface'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const Home = lazy(() => import('./pages/Home'));

const WARMUP_SECS = JSON.parse(process.env.REACT_APP_WARMUP_SECS);

const App = () =>
{
	const email = useRef();
	const [planExpires, setPlanExpires] = useState();
	const [planName, setPlanName] = useState('init_visionary');

	const [guilds, setGuilds] = useState();
	const [levels, setLevels] = useState();

	const sprintSeconds = useRef(process.env.REACT_APP_SPRINT_SECS);
	const time = useRef(new Time(Date.now()));
	const doneFirstLoad = useRef(false);
	const anonymous = useRef(false);

	useEffect(() =>
	{
		if(!doneFirstLoad.current)
		{
			(async ()=>
			{
				const response = await fetchy.get(`${process.env.REACT_APP_BACKEND_SERVER}/getuserinfo`);
				if(response.data.anonymous)
				{
					setPlanName();
					anonymous.current = true;
					return;
				}

				const {/*name,*/ planExpires, planName, now, guilds, levels, todaySprints, ifRefer} = response.data;
				console.log(guilds);
				email.current = response.data.email;
				//setUsername(name);
				setPlanName(planName);
				if(planExpires) setPlanExpires(new Date(planExpires));
				if(todaySprints<WARMUP_SECS.length) sprintSeconds.current = WARMUP_SECS[todaySprints];

				time.current = new Time(now);

				setLevels(levels);
				setGuilds(guilds?.map(e => ({...e, noUpdate:true})));

				if(ifRefer && planName!=='visionary' && !sessionStorage.notifyRefer) 
				{
					notifyReferSubscribe();
					sessionStorage.notifyRefer = 1;
				}
			})();
			doneFirstLoad.current = true;
		}
	}, [doneFirstLoad, anonymous, email, sprintSeconds, setPlanExpires]);

	return (
		<BrowserRouter>
		<Suspense>
			<Routes>
				<Route path="/" element={<Home {...{ planName, currentGuilds:guilds, setCurrentGuilds:setGuilds, time }} />} />
				
				<Route path="/login" element={<LoginSignup mode="login" />} />
				<Route path="/signup" element={<LoginSignup mode="signup" />} />
				<Route path="/resetpassword1" element={<LoginSignup mode="resetpassword1" />} />
				<Route path="/resetpassword2" element={<LoginSignup mode="resetpassword2" />} />
				<Route path="/:page/:urlId?" element={
					<Interface
						{...{
							email,
							planName,
							planExpires,
							levels,
							setLevels,
							guilds,
							setGuilds,
							sprintSeconds,
							anonymous,
							time
						}}
					/>
				} />
			</Routes>
		</Suspense>
		<ToastContainer position="top-center" autoClose={10000} />
		</BrowserRouter>
	);
}

export default App;
