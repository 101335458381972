export const positionTutorialTip = (tipId) =>
{
	const tipEl = document.getElementById(tipId);
	console.log(tipId);
	console.log(tipEl);
	if(tipEl)
	{
		tipEl.classList.add('showtip');
		const rect = tipEl.getBoundingClientRect();
		const screenWidth = window.innerWidth;

		if (rect.left+20 < 0)
		{ // Check if left edge is off screen
			tipEl.style.setProperty('--tiptext-shift', `${Math.abs(rect.left) + 20}px`);
		}

		if (rect.right-20 > screenWidth)
		{ // Check if right edge is off screen
			tipEl.style.setProperty('--tiptext-shift', `${screenWidth - (Math.abs(rect.right)+20)}px`);
		}
	}
}
